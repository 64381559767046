/*
Life is too short to take everything seriously
Does a terminal like output for a fun website.

*/


import React from 'react'
import './FunHomepage.scss'
import '../assets/weather.css'
// assets
import PicOfMe from '../assets/me.png'
import { FiGithub, FiExternalLink } from 'react-icons/fi'

export default function FunHomepage(props) {


  return <div class='Homepage'>
    
  </div>

}


const DisplayText = (text) => {

}
